import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"

import componentGenerator from "@utils/componentGenerator"

export default function Company({ data }) {
  const { contentfulPage } = data
  let components = contentfulPage?.components
  const seo = contentfulPage?.pageSeo
  const simpleHeader = contentfulPage?.simpleHeader
  const simpleHeaderCta = contentfulPage?.simpleHeaderCta
  const simpleFooter = contentfulPage?.simpleFooter
  const seoBreadcrumbs = seo?.breadcrumbs

  return (
    <Layout headerTheme={{ theme: "dark", background: "transparent" }} simpleHeader={simpleHeader} simpleFooter={simpleFooter} simpleHeaderCta={simpleHeaderCta}>
      <Seo
        title={seo?.seoTitle}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        metaTags={seo?.metaTags}
      />
      <div>{componentGenerator("/company", components, seoBreadcrumbs)}</div>
    </Layout>
  )
}

export const companyPageQuery = graphql`
  query companyPageQuery {
    contentfulPage(internalName: { eq: "Company Page" }) {
      id
      __typename
      slug
      id
      simpleHeader
      simpleHeaderCta {
        id
        internalName
        internalLink
        label
        link
        variant
      }
      simpleFooter
      pageSeo {
        graphImage {
          description
          title
          file {
            url
          }
        }
        seoTitle
        seoDescription
        canonical
        noIndex
        metaTags
        article
        breadcrumbs {
          title
          url
          id
        }
      }
      components {
        ... on ContentfulComponentIconBlock {
          __typename
          contentful_id
          internalName
          heading
          kicker
          type
          background
          noTopPadding
          noBottomPadding
          unrestrictImageHeight
          backgroundImage {
            id
            primaryImage {
              gatsbyImageData
              file {
                url
              }
            }
          }
          subheading {
            subheading
          }
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
          }
          icons {
            id
            alignCaption
            caption {
              raw
            }
            description
            internalLink
            internalName
            primaryImage {
              gatsbyImageData
              file {
                url
              }
              description
            }
            title
            url
            featureGridTitle
            featureGridDescription
          }
        }
        ... on ContentfulComponentHero {
          __typename
          id
          type
          background
          alignImage
          subheading {
            raw
          }
          kicker
          heading
          headingSize
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
            reference {
              id
              title
              caption {
                raw
              }
              ctaButton {
                id
                label
                link
              }
            }
          }
          videoThumbnail {
            gatsbyImageData
            file {
              url
            }
          }
          featuredImage {
            gatsbyImageData
            file {
              url
              contentType
            }
          }
        }
        ... on ContentfulComponentCarousel {
          __typename
          id
          type
          subheading {
            subheading
          }
          subheadingTest {
            raw
          }
          heading
          headingSize
          autoplay
          noTopPadding
          noBottomPadding
          carouselItems {
            ... on ContentfulComponentGeneric {
              id
              align
              background
              callsToAction {
                variant
                type
                link
                label
                id
                internalName
                internalLink
                contentful_id
              }
              heading
              headingSize
              subheading {
                raw
              }
              icon {
                gatsbyImageData
                file {
                  url
                }
                title
                description
                contentful_id
              }
              featuredImage {
                gatsbyImageData
                file {
                  url
                }
                title
                description
                contentful_id
              }
              bodyCopy {
                raw
                references {
                  id: spaceId
                  contentful_id
                  link
                  label
                  variant
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
